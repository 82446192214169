import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import QSpinnerClock from 'quasar/src/components/spinner/QSpinnerClock.js';import Platform from 'quasar/src/plugins/Platform.js';;
import Fraction from "fraction.js";

export function FormatDate(date, addTime = false) {
  if (!addTime) {
    return new Date(date).toLocaleString("en-US", {
      timeZone: "America/New_York",
      year: "numeric",
      month: "numeric",
      day: "numeric"
    });
  } else {
    return new Date(date).toLocaleString("en-US", {
      timeZone: "America/New_York",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit"
    });
  }
}

export function Now() {
  var date = new Date();
  return ("00" + (date.getMonth() + 1)).slice(-2) + "/" + ("00" + date.getDate()).slice(-2) + "/" + date.getFullYear();
}

export function FormatDate2(date, addTime = false) {
  if (!addTime) {
    return new Date(date).toLocaleString("en", { year: "numeric", month: "2-digit", day: "2-digit" });
  } else {
    return new Date(date).toDateString("en-US", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit"
    });
  }
}

export function FormatCurrency(value) {
  return Number(value).toLocaleString("en-US", { style: "currency", currency: "USD" });
}

export function MsgBox(message, bgColor = "warning", txtColor = "black", position = "top", timeout = 5000) {
  const isDesktop = Platform.is.mobile ? true : false;

  if (!isDesktop) {
    Notify.create({
      message: "<div class='text-subtitle1 cursor-default' style='z-index: 900000; line-height: 20px'>" + message + "</div>",
      html: true,
      progress: true,
      icon: "warning",
      timeout: timeout,
      color: bgColor,
      textColor: txtColor,
      multiline: true,
      position: position,
      actions: [{ icon: "close", color: txtColor }]
    });
  } else {
    alert(message);
  }
}

export function ShowLoading(text = "Loading Data", pleaseWait = true) {
  try {
    var message = '<span class="text-body1">' + text;
    if (pleaseWait) message += ". Please wait...";

    Loading.show({
      spinner: QSpinnerClock,
      spinnerSize: 40,
      spinnerColor: "white",
      backgroundColor: "black",
      message: message,
      html: true,
      boxClass: "bg-grey-10",
      messageColor: "white"
    });
  } catch (e) {
    alert(e.message);
  }
}

export function HideLoading() {
  Loading.hide();
}

export function ProperCase(str) {
  return str.toLowerCase().replace(/^(.)|\s(.)/g, function ($1) {
    return $1.toUpperCase();
  });
}

export function ToTitleCase(str) {
  str = str.toLowerCase();
  return str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
}

export function TrimDoubleSpace(str) {
  return str.replace(/\s\s+/g, " ").trim();
}

export function Trim(str) {
  return str.replace(/\s/g, "");
}

export function TrimAll(str) {
  return str.replace(" ", "");
}

export function IsValidEmail(str) {
  const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
  return regex.test(str);
}

export function IsValidPassword(str) {
  const regex = /^[A-Za-z0-9!@$#+=._-]*$/;
  return regex.test(str);
}

export function IsValidAccountName(str) {
  const regex = /^[A-Za-z0-9 ]*$/;
  return regex.test(str);
}

export function IsValidName(str) {
  const regex = /^[a-záéíóúüñ ]+$/i;
  return regex.test(str);
}

export function ToCurrency(number) {
  return Number(number).toLocaleString("en-US", { style: "currency", currency: "USD" });
}

export async function SendEmail(form) {
  {
    let results;

    if (form.name == "" || !IsValidName(form.name)) {
      results = { error: true, input: "Name", msg: this.$t("contactUs.errors.invalidName") };
    } else if (form.email == "" || !IsValidEmail(form.email)) {
      results = { error: true, input: "Email", msg: this.$t("contactUs.errors.invalidEmail") };
    } else if (form.subject == "") {
      results = { error: true, input: "Subject", msg: this.$t("contactUs.errors.invalidSubject") };
    } else if (form.message == "") {
      results = { error: true, input: "Message", msg: this.$t("contactUs.errors.invalidMessage") };
    } else {
      const url = "/php/send.php";
      const headers = { "Content-type": "application/json" };

      ShowLoading();

      let results = await this.$axios({
        method: "post",
        url: url,
        headers: { headers },
        mode: "cors",
        data: { form }
      });
      //results = { data: 0 };

      HideLoading();

      results = {
        error: results.data == 0 ? false : true,
        input: null,
        msg: results.data == 0 ? this.$t("contactUs.errors.sent") : this.$t("contactUs.errors.error")
      };
    }

    return results;
  }
}

export async function Alert(Message = "Are you sure you want to leave?") {
  this.$q({
    title: "Alert",
    ok: true,
    cancel: true,
    persistent: true,
    message: Message
  })
    .onOk(() => {
      return true;
    })
    .onCancel(() => {
      return false;
    });
}
