<template>
  <router-view />
</template>

<script>
import { ref, provide } from 'vue';

export default {
  setup() {
    require('dotenv').config({ path: `.env.${process.env.NODE_ENV}.local` });

    const leftDrawerOpen = ref(false);
    provide('leftDrawerOpen', leftDrawerOpen);
  }
};
</script>

<style>
@font-face {
  font-family: 'Futura';
  src: local('Futura'), url(./styles/fonts/FuturaFuturisC.woff) format('woff');
}
</style>
