// directive (v-...) to allow only letters
export const Letters = {
  beforeMount(el, binding, vnode) {
    el.addEventListener(
      'keydown',
      (event) => {
        const chr = window.event.key;
        const regex = /^[a-zA-ZáéíóúñÑÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÝàâãäåçèêëìîïðòôõöùûüýÿ]*$/;

        if (event.keyCode === 32) event.preventDefault();

        if (
          !regex.test(chr) &&
          event.keyCode !== 8 &&
          event.keyCode !== 9 &&
          event.keyCode !== 35 &&
          event.keyCode !== 36 &&
          event.keyCode !== 37 &&
          event.keyCode !== 39 &&
          event.keyCode !== 46
        ) {
          event.preventDefault();
        }

        event.target.value = event.target.value.replace('.', '');
      },
      true
    );
    el.addEventListener(
      'keyup',
      (event) => {
        event.target.value = event.target.value.trim();

        event.target.value = event.target.value.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      },
      true
    );
  }
};

export const LettersNumbersAndDash = {
  beforeMount(el, binding, vnode) {
    el.addEventListener(
      'keydown',
      (event) => {
        const chr = window.event.key;
        const regex = /^[a-zA-Z0-9\-]+$/;

        if (
          !regex.test(chr) &&
          event.keyCode !== 8 &&
          event.keyCode !== 9 &&
          event.keyCode !== 16 &&
          event.keyCode !== 35 &&
          event.keyCode !== 36 &&
          event.keyCode !== 37 &&
          event.keyCode !== 39 &&
          event.keyCode !== 46
        ) {
          event.preventDefault();
        }
      },
      true
    );
    el.addEventListener(
      'keyup',
      (event) => {
        //event.target.value = event.target.value.replace('--', '-');
        //event.target.value = event.target.value.toUpperCase();
      },
      true
    );
  }
};

// directive (v-...) to allow only letters and space
export const LettersAndSpace = {
  beforeMount(el, binding, vnode) {
    el.addEventListener(
      'keydown',
      (event) => {
        const chr = window.event.key;
        const regex = /^[A-Z0-9\s]$/;

        if (
          !regex.test(chr) &&
          event.keyCode !== 8 &&
          event.keyCode !== 9 &&
          event.keyCode !== 35 &&
          event.keyCode !== 36 &&
          event.keyCode !== 37 &&
          event.keyCode !== 39 &&
          event.keyCode !== 46
        ) {
          event.preventDefault();
        }

        event.target.value = event.target.value.replace('  ', ' ');
        event.target.value = event.target.value.replace('.', '');
      },
      true
    );
    el.addEventListener(
      'blur',
      (event) => {
        event.target.value = event.target.value.replace('  ', ' ');
        event.target.value = event.target.value.replace('.', '');
        event.target.value = event.target.value.trim();

        event.target.value = event.target.value.replace(/\w\S*/g, function (txt) {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
      },
      true
    );
  }
};

// directive (v-...) to allow only numbers
export const OnlyNumbers = {
  beforeMount(el, binding, vnode) {
    el.addEventListener(
      'keydown',
      (event) => {
        const chr = window.event.key; //String.fromCharCode(event.keyCode);
        const regex = /^\d+$/;

        if (
          !regex.test(chr) &&
          event.keyCode !== 8 &&
          event.keyCode !== 9 &&
          event.keyCode !== 35 &&
          event.keyCode !== 36 &&
          event.keyCode !== 37 &&
          event.keyCode !== 39 &&
          event.keyCode !== 46
        ) {
          event.preventDefault();
        }
      },
      true
    );
  }
};
