import { createApp } from 'vue';
import App from '@/App.vue';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';

const app = createApp(App).use(Quasar, quasarUserOptions);

// mount router
import router from '@/router';
app.use(router);

// mount store (vuex)
// use: import store from '@/store';
//      store.state.XXXX.YYYY to get value
//      store.dispatch(dispatch_function, paylaoad)
import store from '@/store';
app.use(store);

// v- directives
// use in <input...
// example: <input type='text' v-letters to accept only letters
//          <input type='text' v-letters-and-space accept only letters ans spaces
//          <input type='text' v-only-numbers to accept only numbers
import { Letters, LettersNumbersAndDash, LettersAndSpace, OnlyNumbers } from '@/directives';
app.directive('Letters', Letters);
app.directive('LettersNumbersAndDash', LettersNumbersAndDash);
app.directive('LettersAndSpace', LettersAndSpace);
app.directive('OnlyNumbers', OnlyNumbers);

// html to pdf

// mount app
app.mount('#app');
