import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

import { MsgBox } from '@/library';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/layouts/Login.vue'),
    children: [{ path: '', component: () => import(/* webpackChunkName: "Home" */ '@/views/Login.vue') }]
  },
  {
    path: '/main',
    name: 'main',
    meta: { requiresAuth: true },
    component: () => import('@/layouts/Main.vue'),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    children: [{ path: '', component: () => import(/* webpackChunkName: "About" */ '@/views/Main.vue') }]
  },
  {
    path: '/traveler',
    name: 'traveler',
    meta: { requiresAuth: true },
    component: () => import('@/layouts/Main.vue'),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    children: [{ path: '', component: () => import(/* webpackChunkName: "About" */ '@/views/Traveler.vue') }]
  },
  {
    path: '/xml',
    name: 'xml',
    meta: { requiresAuth: true },
    component: () => import('@/layouts/Main.vue'),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    children: [{ path: '', component: () => import(/* webpackChunkName: "About" */ '@/views/Xml.vue') }]
  },
  {
    path: '/table',
    name: 'table',
    meta: { requiresAuth: true },
    component: () => import('@/layouts/Main.vue'),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    children: [{ path: '', component: () => import(/* webpackChunkName: "About" */ '@/views/Table.vue') }]
  },
  {
    path: '/slider',
    name: 'slider',
    meta: { requiresAuth: true },
    component: () => import('@/layouts/Main.vue'),
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    children: [{ path: '', component: () => import(/* webpackChunkName: "About" */ '@/views/Slider.vue') }]
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/views/Error404.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),

  routes
});

// Authenticate before navigating to route
router.beforeEach((to, from, next) => {
  const IsLogged = store.state.Depot.IsLogged;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // This route requires auth, check if logged in, if not, redirect to login page.
    if (!IsLogged) {
      MsgBox('Access Denied. You must Login first');
      next('/', { replace: true });
    } else {
      next().replace();
    }
  } else {
    // Make sure to always call next()!
    next().replace();
  }
});

export default router;
