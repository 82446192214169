import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';

const ls = new SecureLS({
  key: 'sea_inhouse',
  encodingType: 'aes',
  isCompression: false,
  encryptionSecret: '10124549'
});

// authorization store (vuex)
const depot = {
  namespaced: true,

  // states
  state() {
    return {
      IsLogged: false,
      LeftDrawer: false,
      UserInfo: {}
    };
  },

  getters: {
    IsLogged(state) {
      return state.IsLogged;
    },
    UserInfo(state) {
      return state.CustomerInfo;
    },
    LeftDrawer(state) {
      return state.LeftDrawer;
    }
  },

  // dispatch actions
  actions: {
    async IsLogged(context, payload) {
      await context.commit('IS_LOGGED', payload);
    },

    async UserInfo(context, payload) {
      await context.commit('USER_INFO', payload);
    },

    async LeftDrawer(context, payload) {
      await context.commit('LEFT_DRAWER', payload);
    }
  },

  // do not call mutations directly, call them using a dispatch action
  mutations: {
    async IS_LOGGED(state, payload) {
      state.IsLogged = payload;
    },

    async USER_INFO(state, payload) {
      state.UserInfo = payload;
    },

    async LEFT_DRAWER(state, payload) {
      state.LeftDrawer = payload;
    }
  }
};

// create store
let store;

try {
  store = createStore({
    modules: {
      Depot: depot
    },
    plugins: [
      createPersistedState({
        key: 'sea_inhouse',
        overwrite: true,
        fetchBeforeUse: true,
        storage: {
          getItem: (key) => ls.get(key),
          setItem: (key, value) => ls.set(key, value),
          removeItem: (key) => ls.remove(key)
        }
      })
    ]
  });
} catch (error) {
  window.localStorage.clear();
  alert('ATTENTION!\n\nSome settings have been tampered with\n\nBad User! Bad User!\n\nContact us to reset your credentials');
}

// add the stores
store.state.Depot;

export default store;
