import './styles/quasar.scss';
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import '@quasar/extras/mdi-v5/mdi-v5.css';

import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';;

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: { Notify, Loading, Dialog }
};
